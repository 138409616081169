/**
 * Footer
 */

.ly_footer {
	background-color: var(--blue);
	overflow-x: clip;
	color: #fff;
	@include mq_not_maxW {
		padding-block: 120px 75px;
	}
	@include mq_maxW {
		padding-block: 45px 55px;
	}
	&_inner {
		margin: auto;
		width: var(--contW);
	}
	&_head {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 18px 44px;
		padding-bottom: 20px;
		border-bottom: 1px solid currentColor;
		@include mq_maxW {
			width: calc(100% -  calc((670 / var(--designW) * var(--htmlW) - var(--htmlW)) / 2));
		}
	}
	&_logo {
		width: 174px;
		height: 54px;
		overflow: hidden;
		a {
			display: block;
			width: 100%;
			height: 100%;
			transition: opacity var(--transDur);
			@include action {
				opacity: 0.6;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}
	&_name {
		line-height: 1.4;
		&_sub {
			@include mq_not_maxW {
				font-size: fz_vw_clamp(34);
			}
			@include mq_maxW {
				font-size: fz(20);
			}
		}
		&_main {
			@include mq_not_maxW {
				margin-left: 16px;
				font-size: fz_vw_clamp(49);
			}
			@include mq_maxW {
				margin-left: 10px;
				font-size: fz(30);
			}
		}
	}
	&_body {
		padding-block: 15px 32px;
		border-bottom: 1px solid #fff;
		@include mq_maxW {
			margin-left: calc((670 / var(--designW) * var(--htmlW) - var(--htmlW)) / 2);
			width: 100vw;
			padding-inline: var(--contMargin_hor);
		}
	}
	&_nav {
		>ul {
			display: flex;
			@include mq_not_maxW {
				align-items: center;
			}
			@include mq_maxW {
				flex-direction: column;
				gap: 0.3em;
			}
			li {
				line-height: 1.25;
				letter-spacing: 0.1em;
				&::after {
					content: "｜";
				}
				&:first-child {
					&::before {
						content: "｜";
					}
				}
				@include mq_not_maxW {
					font-size: fz_vw_clamp(18);
				}
				@include mq_maxW {
					margin-left: -0.5em;
					font-size: fz(21);
					&:not(:first-child) {
						&::before {
							content: "｜";
						}
					}
				}
				>a {
					position: relative;
					display: inline-block;
					@include addLinedLink;

					@include mq_not_maxW {
						padding-bottom: 5px;
					}
				}
			}
		}
	}
	&_other {
		display: flex;
		justify-content: space-between;
		gap: 25px;
		@include mq_not_maxW {
			margin-top: 50px;
			align-items: center;
		}
		@include mq_maxW {
			margin-top: 40px;
			flex-direction: column;
		}
	}
	&_univLogo {
		flex-shrink: 0;
		height: auto;
		aspect-ratio: 393/102;
		@include mq_not_maxW {
			width: calc(393 / 1200 * 100%);
		}
		@include mq_maxW {
			width: 63%;
		}
		>a {
			display: block;
			width: 100%;
			height: 100%;
			transition: opacity var(--transDur);
			@include action {
				opacity: 0.6;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}
	&_subNav {
		display: flex;
		gap: 10px 50px;
		@include mq_maxW {
			flex-direction: column;
		}
		&_hdg {
			padding-bottom: 8px;
			margin-bottom: 15px;
			border-bottom: 1px dotted currentColor;
			letter-spacing: 0.1em;
			line-height: 1.45;
			@include mq_not_maxW {
				font-size: fz_vw_clamp(16);
			}
			@include mq_maxW {
				font-size: fz(14);
			}
		}
	}
	&_fund {
		flex-shrink: 0;
		@include mq_not_maxW {
			width: calc(221 / 1200 * min(calc(1200 / var(--designW) * var(--htmlW)), 1200px));
		}
		>a {
			position: relative;
			letter-spacing: 0.13em;
			line-height: 1.4;
			@include mq_not_maxW {
				font-size: fz_vw_clamp(24);
			}
			@include mq_maxW {
				font-size: fz(18);
			}
			@include addLinedLink;
		}
	}
	&_sns {
		>ul {
			display: flex;
			flex-wrap: wrap;
			@include mq_not_maxW {
				gap: 20px min(40px, calc(40 / (var(--designW) + var(--scrollbarW)) * 100vw));
			}
			@include mq_maxW {
				gap: 20px 40px;
			}
		}
		&_img {
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			@include mq_not_maxW {
				height: calc(56 / 1200 * min(calc(1200 / var(--designW) * var(--htmlW)), 1200px));
			}
			@include mq_maxW {
				height: 33px;
			}
			img {
				max-height: 100%;
			}
		}
		&_label {
			position: relative;
			width: fit-content;
			text-align: center;
			margin: 0 auto;
			@include mq_not_maxW {
				font-size: fz_vw_clamp(18);
			}
		}
		a {
			display: grid;
			justify-content: center;
			@include addLinedLink('.ly_footer_sns_label');
		}
	}
	&_foot {
		margin-top: 23px;
	}
	&_link {
		>ul {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			gap: 0.5em 1em;
			li {
				letter-spacing: 0.1em;
				@include mq_not_maxW {
					font-size: fz(15);
				}
				@include mq_maxW {
					font-size: fz(14);
				}
				>a {
					position: relative;
					@include addLinedLink;
				}
			}
		}
	}
	&_copyright {
		letter-spacing: 0.1em;
		text-align: center;
		@include mq_not_maxW {
			font-size: fz(15);
		}
		@include mq_maxW {
			margin-top: 13px;
			font-size: fz(14);
		}
	}
}
