// 追加のスタイルリセット
::placeholder {
	opacity: 1;
}

label {
	cursor: pointer;
}

img,
iframe,
video {
	vertical-align: top;
}

dt {
	font-weight: inherit;
}

small {
	font-size: inherit;
}

:where(dialog) {
	inset: 0;
	border: unset;
	padding: unset;
	width: unset;
	max-width: unset;
	height: unset;
	max-height: unset;
	background-color: unset;
	overflow: unset;
	color: unset;
}

:focus:not(:focus-visible) {
	outline: none;
}
