.tp_0_0 {
	font-size: fz(18);
	.ly_cont {
		padding-top: 0;
		@include mq_not_maxW {
			padding-bottom: 140px;
		}
	}
	section:not(.mvSect) {
		position: relative;
		background-color: #fff;
		overflow-x: clip;
		z-index: 2;
		@include mq_not_maxW {
			padding-block: 41px 114px;
		}
		@include mq_maxW {
			padding-block: 31px 80px;
		}
		&:last-child {
			padding-bottom: 0;
		}
		&.researchSect,
		&.introSect {
			background-color: var(--gray);
		}
		&.aboutSect {
			background-color: var(--blue);
			color: #fff;
			.sectTtl,
			.sectHdg {
				color: #fff;
			}
		}
	}
	.sectTtl {
		font-weight: 800;
		line-height: 1.05;
		color: var(--blue);
		@include mq_not_maxW {
			margin-block: var(--negLeading) calc(128px + var(--negLeading));
			margin-left: 27px;
			font-size: fz_vw_clamp(96);
		}
		@include mq_maxW {
			margin-block: var(--negLeading) calc(80px + var(--negLeading));
			margin-left: 10px;
			font-size: fz(34);
		}
	}
	.sectHdg {
		margin-bottom: 16px;
		font-weight: 500;
		color: var(--blue);
		@include mq_not_maxW {
			font-size: fz(28);
		}
		@include mq_maxW {
			font-size: fz(24);
		}
	}
	.sectCopy {
		@include mq_not_maxW {
			font-size: fz(20);
		}
		@include mq_maxW {
			font-size: fz(16);
		}
	}
	.sectBtn {
		display: flex;
		justify-content: flex-end;
		@include mq_not_maxW {
			margin-top: 40px;
		}
		@include mq_maxW {
			margin-top: 20px;
		}
		>a {
			position: relative;
			display: flex;
			align-items: center;
			font-weight: 500;
			letter-spacing: 0.1em;
			color: var(--blue);
			transition: color var(--transDur);
			@include mq_not_maxW {
				gap: 18px;
				font-size: fz(26);
			}
			@include mq_maxW {
				gap: 11px;
				font-size: fz(18);
			}
			&::after {
				content: "";
				display: inline-block;
				clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
				background-color: var(--blue);
				@include mq_not_maxW {
					width: 50px;
					height: 44px;
				}
				@include mq_maxW {
					width: 34px;
					height: 30px;
				}
			}
			@include action {
				color: var(--hover);
				&::after {
					animation: rotateZ 0.5s infinite both;
				}
			}
		}
	}
	.sectInner {
		display: grid;
		@include mq_not_maxW {
			grid-template-columns: repeat(2,1fr);
			column-gap: 7%;
		}
		@include mq_maxW {
			grid-template-columns: minmax(0,1fr);
			row-gap: 30px;
		}
		.sectWrapper {
			display: grid;
			grid-template-columns: minmax(0,1fr);
			align-content: flex-start;
			grid-template-rows: auto auto 1fr;
			.sectFoot {
				margin-top: 12px;
				align-self: flex-end;
			}
		}
		.sectImg {
			width: 100%;
			overflow: hidden;
			img {
				width: 100%;
				object-fit: contain;
			}
		}
	}

	// MV
	.mvSect {
		position: relative;
		overflow-x: clip;
		z-index: 1;
		@include mq_not_maxW {
			padding-block: 200px 210px;
		}
		@include mq_maxW {
			padding-block: 144px 60px;
		}
		&_bg {
			position: absolute;
			width: fit-content;
			top: 0;
			left: 50%;
			overflow-x: clip;
			z-index: 1;
			@include mq_not_maxW {
				height: calc(100% + 1062px);
				transform: translateX(-30%);
			}
			@include mq_maxW {
				height: calc(100% + 412px);
				transform: translateX(-36%);
			}
		}
		&_video {
			position: sticky;
			height: auto;
			aspect-ratio: 1;
			overflow: hidden;
			@include mq_not_maxW {
				top: 170px;
				width: 1062px;
			}
			@include mq_maxW {
				top: 122px;
				width: 412px;
			}
			video {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				@include mq_not_maxW {
					width: calc(1920px * 1062 / 750);
					// transform: translateX(-42%);
				}
				@include mq_maxW {
					width: calc(1920px * 412 / 750);
					// transform: translateX(-46%);
				}
			}
		}
		&_content {
			position: relative;
			z-index: 2;
		}
		&_textArea {
		}
		&_hdg {
			>span {
				box-decoration-break: clone;
				font-weight: 500;
				color: var(--blue);
				@include mq_not_maxW {
					padding-block: 12px;
					background-image: linear-gradient(transparent 15%, var(--lightblue) 15%, var(--lightblue) 90%, transparent 90%);
					font-size: fz_vw_clamp(54);
				}
				@include mq_maxW() {
					display: grid;
				}
			}
			&_main {
				@include mq_not_maxW {
					padding-inline: min(18px, calc(18 / (var(--designW) + var(--scrollbarW)) * 100vw));
					font-size: fz_vw_clamp(54);
				}
				@include mq_maxW {
					display: inline-block;
					width: fit-content;
					padding: 3px 10px;
					background-image: linear-gradient(transparent 15%, var(--lightblue) 15%, var(--lightblue) 90%, transparent 90%);
					font-size: fz(36);
				}
			}
			&_sub {
				@include mq_not_maxW {
					padding-inline: min(12px, calc(12 / (var(--designW) + var(--scrollbarW)) * 100vw));
					font-size: fz_vw_clamp(36);
				}
				@include mq_maxW() {
					display: inline-block;
					width: fit-content;
					padding: 3px 10px;
					background-image: linear-gradient(transparent 15%, var(--lightblue) 15%, var(--lightblue) 90%, transparent 90%);
					font-size: fz(24);
				}
			}
		}
		&_copy {
			>span {
				background-image: linear-gradient(transparent 15%, #ebebeb 15%, #ebebeb 90%, transparent 90%);
				font-weight: 500;
				color: var(--blue);
				@include mq_not_maxW {
					padding: 3px min(18px, calc(18 / (var(--designW) + var(--scrollbarW)) * 100vw));
					font-size: fz_vw_clamp(35);
				}
				@include mq_maxW {
					padding: 3px 12px;
					font-size: fz(27);
				}
			}
		}
		&_link {
			>a {
				position: relative;
				background-image: linear-gradient(transparent 15%, var(--blue) 15%, var(--blue) 90%, transparent 90%);
				font-weight: 500;
				color: #fff;
				transition: color 0.3s;
				@include mq_not_maxW {
					padding: 5px min(20px, calc(20 / (var(--designW) + var(--scrollbarW)) * 100vw));
					font-size: fz_vw_clamp(34);
				}
				@include mq_maxW {
					padding: 5px 12px;
					font-size: fz(26);
				}
				>span {
					position: relative;
					z-index: 2;
				}
				&::before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-image: linear-gradient(transparent 15%, var(--lightblue) 15%, var(--lightblue) 90%, transparent 90%);
					transform-origin: center;
					// transform: scaleX(0);
					scale: 0 100%;
					transition: scale var(--transDur);
					z-index: 1;
				}
				&::after {
					content: "";
					position: relative;
					display: inline-block;
					height: auto;
					aspect-ratio: 15/8;
					mask-image: url(../images/icon/icon_doubleArw.svg);
					mask-size: contain;
					mask-repeat: no-repeat;
					background-color: currentColor;
					z-index: 3;
					@include mq_not_maxW {
						margin-left: 18px;
						width: min(48px, calc(48 / (var(--designW) + var(--scrollbarW)) * 100vw));
					}
					@include mq_maxW {
						margin-left: 11px;
						width: 38px;
					}
				}
				@include action {
					color: var(--blue);
					&::before {
						// animation: scaleX 0.3s both ease-in-out;
						scale: 100% 100%;
					}
				}
			}
		}
		&_slideTxt {
			display: flex;
			overflow-x: clip;
			@include mq_not_maxW {
				margin-top: 45px;
			}
			@include mq_maxW {
				margin-top: 26px;
			}
			span {
				animation: loopX 60s infinite linear;
				display: inline-block;
				padding-left: 0.5em;
				width: fit-content;
				white-space: nowrap;
				flex-shrink: 0;
				font-weight: 800;
				line-height: 1;
				color: var(--blue);
				@include mq_not_maxW {
					font-size: fz(160);
				}
				@include mq_maxW {
					font-size: fz(100);
				}
			}
		}
		&_slideImg {
			&_lg {
				@include mq_not_maxW {
					margin-top: 40px;
				}
				@include mq_maxW {
					margin-top: 10px;
				}
				.splide {
					&__slide {
						height: auto;
						aspect-ratio: 425/242;
						overflow: hidden;
						@include mq_not_maxW {
							width: calc(1560px / 3);
						}
						@include mq_maxW {
							width: calc(653px / 3);
						}
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
				}
			}
			&_sm {
				margin-top: 15px;
				.splide {
					&__slide {
						width: 425px;
						height: auto;
						aspect-ratio: 425/242;
						overflow: hidden;
						@include mq_not_maxW {
							width: 340px;
						}
						@include mq_maxW {
							width: calc(653px / 4);
						}
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
				}}
		}
	}


	// 複合型研究会
	.introCard {
		$_root: '.introCard';
		@include mq_not_maxW {
			margin-top: 45px;
		}
		@include mq_maxW {
			margin-top: 36px;
		}
		>ul {
			display: grid;
			@include mq_not_maxW {
				grid-template-columns: repeat(2,minmax(0,1fr));
				column-gap: 5%;
				row-gap: 48px;
			}
			@include mq_maxW {
				grid-template-columns: minmax(0,1fr);
				row-gap: 24px;
			}
		}
		&_thumb {
			position: relative;
			aspect-ratio: 56/29;
			overflow: hidden;
			img {
				position: relative;
				width: 100%;
				height: 100%;
				object-fit: cover;
				transform-origin: center;
				transition: transform var(--transDur);
				z-index: 1;
			}
			.el_iconArw {
				position: absolute;
				bottom: 0;
				right: 0;
				height: auto;
				aspect-ratio: 1;
				background-color: #fff;
				z-index: 3;
				@include mq_not_maxW {
					width: 60px;
				}
				@include mq_maxW {
					width: 30px;
				}
			}
			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: #000;
				opacity: 0;
				visibility: hidden;
				transition-property: opacity,visibility;
				transition-duration: var(--transDur);
				z-index: 2;
			}
		}
		&_label {
			@include mq_not_maxW {
				margin-top: 30px;
			}
			@include mq_maxW {
				margin-top: 15px;
			}
		}
		&_hdg {
			font-weight: 500;
			color: var(--blue);
			transition: color var(--transDur);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			@include mq_not_maxW {
				padding-right: 3em;
				margin-bottom: 18px;
				font-size: fz(22);
			}
			@include mq_maxW {
				padding-right: 1em;
				margin-bottom: 10px;
				font-size: fz(20);
			}
		}
		&_txt {
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			overflow: hidden;
			@include mq_not_maxW {
				font-size: fz(16);
			}
			@include mq_maxW {
				font-size: fz(14);
			}
		}
		a {
			@include action {
				#{$_root} {
					&_thumb {
						&::before {
							opacity: 0.3;
							visibility: visible;
						}
						img {
							transform: scale(1.02);
						}
					}
					&_hdg {
						color: var(--hover);
					}
				}
			}
		}
	}

	// ニュース
	.newsSect {
		.categoryList {
			@include mq_not_maxW {
				margin-bottom: 45px;
			}
			@include mq_maxW {
				margin-bottom: 36px;
			}
			>ul {
				display: flex;
				flex-wrap: wrap;
				>li {
					position: relative;
					display: grid;
					place-content: center;
					margin-right: -1px;
					padding-inline: 15px;
					font-weight: 600;
					color: var(--blue);

					&:before {
						content: "";
						position: absolute;
						top: 50%;
						left: 0;
						width: 1px;
						height: 1em;
						background-color: currentColor;
						translate: 0 -50%;
					}

					&:after {
						content: "";
						position: absolute;
						top: 50%;
						right: 0;
						width: 1px;
						height: 1em;
						background-color: currentColor;
						translate: 0 -50%;
					}

					>button {
						@include addLinedLink;

						&.is_selected {
							background-size: 100% 2px;
						}
					}
				}
			}
		}

		&_tab {
		}
	}

	// ABOUT US、FEATURES
	.boxedBtn {
		@include mq_maxW {
			text-align: right;
		}
		>a {
			position: relative;
			display: inline-block;
			font-weight: 500;
			line-height: 1;
			color: #fff;
			transition: color var(--transDur);
			@include mq_not_maxW {
				padding: 1px 40px 3px 10px;
				font-size: fz(26);
			}
			@include mq_maxW {
				padding: 0 21px 1px 4px;
				font-size: fz(16);
			}
			>span {
				position: relative;
				z-index: 2;
			}
			.el_iconArw {
				position: absolute;
				top: 50%;
				right: 0;
				width: auto;
				height: 100%;
				aspect-ratio: 1;
				transform: translateY(-50%);
			}
			&::before {
				content: "";
				position: absolute;
				display: block;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: var(--blue);
				mix-blend-mode: multiply;
			}
			&::after {
				content: "";
				position: absolute;
				display: block;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: var(--lightblue);
				// transform: scaleX(0);
				scale: 0 100%;
				transition: scale var(--transDur);
				z-index: 1;
			}
			@include action {
				color: var(--blue);
				&::after {
					// animation: scaleX 0.3s both ease-in-out;
					scale: 100% 100%;
				}
			}
		}
	}

	// 教員紹介
	.teacher {
		@include mq_not_maxW {
			margin-top: 54px;
		}
		@include mq_maxW {
			margin-top: 34px;
		}
	}

	// アクセス
	.access {
		>address {
			@include mq_not_maxW {
				font-size: fz(18);
			}
			@include mq_maxW {
				font-size: fz(16);
			}
		}
		&_mail {
			@include mq_not_maxW {
				margin-top: 23px;
			}
			@include mq_maxW {
				margin-top: 10px;
			}
			>a {
				position: relative;
				display: flex;
				align-items: center;
				width: fit-content;
				@include mq_not_maxW {
					gap: 28px;
					font-size: fz(18);
				}
				@include mq_maxW {
					gap: 14px;
					font-size: fz(16);
				}
				>span {
					position: relative;
				}
				@include addLinedLink('>span');
				&:before {
					content: "";
					display: inline-block;
					height: auto;
					aspect-ratio: 30/19;
					background-image: url(../images/icon/icon_mail.svg);
					background-repeat: no-repeat;
					background-size: contain;
					@include mq_not_maxW {
						width: 58px;
					}
					@include mq_maxW {
						width: 30px;
					}
				}
			}
		}
		&_map {
			width: var(--htmlW);
			@include mq_not_maxW {
				margin-left: min(calc((1200px - var(--htmlW)) / 2), calc((1200 / 1360 * var(--htmlW) - var(--htmlW)) / 2));
				margin-block: 48px 57px;
			}
			@include mq_maxW {
				margin-left: calc((670 / var(--designW) * var(--htmlW) - var(--htmlW)) / 2);
				margin-block: 20px 30px;
			}
			>iframe {
				width: 100%;
				@include mq_not_maxW {
					height: auto;
					aspect-ratio: 1440/664;
				}
				@include mq_maxW {
					height: 480px;
				}
			}
		}
	}
}
