.tp_2_1 {
	.newsDetailHeader {
		margin-bottom: 70px;
		@include mq_maxW() {
			margin-bottom: 40px;
		}
		.hdg {
			margin-bottom: 20px;
			font-size: fz(34);
			font-weight: bold;
			color: var(--blue);
			@include mq_maxW() {
				font-size: fz(27);
			}
		}
		.category {
			display: flex;
			gap: 10px;
			&_item {
				display: inline-block;
				width: fit-content;
				font-weight: 500;
				line-height: 1;
				color: #fff;
				@include mq_not_maxW {
					padding: 4px 6px;
					font-size: fz(21);
				}
				@include mq_maxW {
					padding: 4px 6px;
					font-size: fz(17);
				}
				&.__blue {
					background-color: var(--blue);
				}
				&.__pink {
					background-color: #e19bd2;
				}
				&.__gray {
					background-color: #494949;
				}
				&.__lightblue {
					background-color: #23afe1;
				}
			}
		}
		time {
			display: inline-block;
			margin-top: 15px;
			font-weight: 500;
			font-size: fz(25);
			@include mq_maxW {
				margin-top: 5px;
				font-size: fz(19);
			}
		}
	}

	.newsDetailFooter {
		margin-top: 100px;
		@include mq_maxW() {
			margin-top: 60px;
		}

		.btn {
			margin: 0 auto;
			width: fit-content;

			>a {
				position: relative;
				display: grid;
				place-content: center;
				padding-left: 35px;
				padding-bottom: 3px;
				min-width: 200px;
				background-color: var(--gray);
				font-weight: 500;
				font-size: fz(20);
				line-height: 1.5;
				letter-spacing: 0.1em;
				color: var(--blue);
				transition-property: background-color, color;
				transition-duration: var(--transDur);

				&:before {
					content: "";
					position: absolute;
					display: inline-block;
					top: 50%;
					left: 23px;
					width: 12px;
					height: 12px;
					mask-image: url(../images/icon/icon_singleArw.svg);
					mask-size: contain;
					mask-repeat: no-repeat;
					background-color: currentColor;
					translate: 0 -50%;
				}

				@include action {
					background-color: var(--blue);
					color: #fff;
				}
			}
		}
	}
}
