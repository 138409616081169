/**
 * Base
 */

// @font-face {
// 	font-family: "MyFont";
// 	src: url("myfont.eot");
// 	src: url("myfont.eot?#iefix") format("embedded-opentype"),
// 		// url("myfont.woff2") format("woff2"),
// 		url("myfont.woff") format("woff"),
// 		url("myfont.ttf") format("truetype");
// }

// *,
// ::before,
// ::after {
// 	letter-spacing: 0.04em;
// }

:root {
	interpolate-size: allow-keywords;
}
html,
body {
	height: 100%;
}
html {
	&.is_locked {
		position: fixed;
		width: 100%;
		overscroll-behavior: none;
	}
}
body {
	color: #494949;
	font-family: "Noto Sans JP", serif;
	font-size: fz(16);
	line-height: 1.6;
	overflow-wrap: anywhere;
	// line-break: normal;
	-moz-osx-font-smoothing: unset;
	-webkit-font-smoothing: subpixel-antialiased;
	-webkit-text-size-adjust: 100%;
	@media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
	}
	@include mq_maxW() {
		font-size: fz(16);
	}
}

a {
	@include action {
		img {}
	}
}

img {
	max-width: 100%;
	height: auto;
}
