/**
 * Helper
 */

.hp_pcOnly {
	@include mq_maxW() {
		display: none !important;
	}
}
.hp_spOnly {
	@include mq_not_maxW() {
		display: none !important;
	}
}

// テキストのline-heightの影響で他の要素との余白が大きくなるときの調整用
// 詳細: https://www.notion.so/line-height-137ca5210c60801c864ec34fc168b876
.hp_trimLeading {
	&::before,
	&::after {
		content: "";
		display: block;
	}
	&::before {
		margin-block-end: var(--negLeading);
	}
	&::after {
		margin-block-start: var(--negLeading);
	}
}
