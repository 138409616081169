.js_animation_target {
	opacity: 0;
}
.js_animation_start {
	&.animation_scaleDown {
		transform: scale(1.1);
		animation-name: scaleDown;
		animation-duration: .5s;
		animation-timing-function: ease-in-out;
		// animation-delay: .1s;
		animation-iteration-count: 1;
		animation-direction: normal;
		animation-fill-mode: both;
		animation-play-state: running;
	}

	&.animation_popShow {
		transform: scale(0);
		animation-name: popShow;
		animation-duration: .7s;
		animation-timing-function: ease-in-out;
		animation-delay: .5s;
		animation-iteration-count: 1;
		animation-direction: normal;
		animation-fill-mode: forwards;
		animation-play-state: running;
	}

	&.animation_fadeInD2U {
		opacity: 0;
		animation-name: fadeInD2U;
		animation-duration: .4s;
		animation-timing-function: ease-in-out;
		animation-delay: .5s;
		animation-iteration-count: 1;
		animation-direction: normal;
		animation-fill-mode: forwards;
		animation-play-state: running;
	}

	&.animation_fadeInR2L {
		opacity: 0;
		animation-name: fadeInR2L;
		animation-duration: .4s;
		animation-timing-function: ease-in-out;
		animation-delay: .5s;
		animation-iteration-count: 1;
		animation-direction: normal;
		animation-fill-mode: forwards;
		animation-play-state: running;
	}

	&.animation_fadeInL2R {
		opacity: 0;
		animation-name: fadeInL2R;
		animation-duration: .4s;
		animation-timing-function: ease-in-out;
		animation-delay: .5s;
		animation-iteration-count: 1;
		animation-direction: normal;
		animation-fill-mode: forwards;
		animation-play-state: running;
	}

	&.animation_slideInL2R {
		animation: slideInL2R .4s linear both;
		overflow: hidden;
		opacity: 0;
	}
}

.js_animation_wrapper {
	&.js_animation_start {
		opacity: 1;
		.animation_fadeInD2U {
			opacity: 0;
			animation-name: fadeInD2U;
			animation-duration: .5s;
			animation-timing-function: ease-in-out;
			animation-delay: .3s;
			animation-iteration-count: 1;
			animation-direction: normal;
			animation-fill-mode: forwards;
			animation-play-state: running;
		}
		.animation_popShow {
			opacity: 0;
			animation-name: popShow;
			animation-duration: 1s;
			animation-timing-function: ease-in-out;
			animation-delay: .6s;
			animation-iteration-count: 1;
			animation-direction: normal;
			animation-fill-mode: forwards;
			animation-play-state: running;
		}
		.animation_fadeInD2U_delay {
			opacity: 0;
			animation-name: fadeInD2U;
			animation-duration: .5s;
			animation-timing-function: ease-in-out;
			animation-delay: .6s;
			animation-iteration-count: 1;
			animation-direction: normal;
			animation-fill-mode: forwards;
			animation-play-state: running;
		}
	}
}

.js_animation_consecutive {
	&.js_animation_start {
		opacity: 1;
		.animation_fadeInD2U {
			opacity: 0;
			animation-name: fadeInD2U;
			animation-duration: .5s;
			animation-timing-function: ease-in-out;
			animation-iteration-count: 1;
			animation-direction: normal;
			animation-fill-mode: forwards;
			animation-play-state: running;
			$max-items: 60;
			@for $i from 1 through $max-items {
				&:nth-child(#{$i}) {
					animation-delay:  #{.3 + calc($i / 10)} + 's';
				}
			}
		}
	}
}
