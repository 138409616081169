/**
 * Layout
 */

.ly_wrapper {}

.ly_cont {
	padding-block: 200px 80px;
	@include mq_maxW() {
		padding-top: 135px;
	}
	&_main {
	}
}
