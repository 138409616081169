.tp_2_0 {
	.newsListHeader {
		margin-bottom: 90px;
		@include mq_maxW() {
			margin-bottom: 60px;
		}
		.categoryList {
			>ul {
				display: flex;
				flex-wrap: wrap;
				>li {
					position: relative;
					display: grid;
					place-content: center;
					margin-right: -1px;
					padding-inline: 22px;
					font-weight: 600;
					font-size: fz(21);
					color: var(--blue);

					@include mq_maxW() {
						font-size: fz(18);
						padding-inline: 18px;
					}

					&:before {
						content: "";
						position: absolute;
						top: 50%;
						left: 0;
						width: 1px;
						height: 1em;
						background-color: currentColor;
						translate: 0 -50%;
					}

					&:after {
						content: "";
						position: absolute;
						top: 50%;
						right: 0;
						width: 1px;
						height: 1em;
						background-color: currentColor;
						translate: 0 -50%;
					}

					>a {
						@include addLinedLink;

						&.is_current {
							background-size: 100% 2px;
							pointer-events: none;
						}
					}
				}
			}
		}
	}

	.bl_news {
		&_head {
			@include mq_maxW() {
				display: block;
				margin-bottom: 10px;
			}
		}
	}

	.newsListFooter {
		margin-top: 100px;
		@include mq_maxW() {
			margin-top: 60px;
		}
		.pagination {
			display: flex;
			justify-content: center;
			gap: 40px;
			font-weight: 600;
			font-size: fz(24);
			line-height: 1;
			color: var(--blue);

			@include mq_maxW() {
				gap: 16px;
			}

			.page-numbers {
				position: relative;
				display: grid;
				place-content: center;
				padding-bottom: 2px;
				width: 32px;
				aspect-ratio: 1;
				transition-property: background-color, color;
				transition-duration: var(--transDur);

				&:not(.prev,.next,.dots) {
					background-color: var(--gray);
				}

				&.prev,
				&.next {
					&:before {
						content: "";
						position: absolute;
						top: 50%;
						left: 50%;
						width: 12px;
						height: 12px;
						mask-image: url(../images/icon/icon_singleArw.svg);
						mask-size: contain;
						mask-repeat: no-repeat;
						background-color: currentColor;
						translate: -50% -50%;
					}
				}
				&.next {
					&:before {
						scale: -1 1;
					}
				}

				&:is(a) {
					@include action {
						background-color: var(--blue);
						color: #fff;
					}
				}

				&.current {
					background-color: var(--blue);
					color: #fff;
				}
			}
		}
	}
}
