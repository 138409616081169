/**
 * Container
 */

.ct_std {
	margin-inline: auto;
	width: var(--contW);
	@include mq_maxW() {}
	&:has(.ct_inner) {
		@include mq_not_maxW() {
			display: grid;
			grid-template-columns: repeat(3,minmax(0,1fr));
			.ct_inner {
				grid-column: span 2;
			}
		}
	}
}
