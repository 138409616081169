/**
 * Block
 */

.bl_breadcrumb {
	margin-bottom: 70px;
	ol {
		display: flex;
		flex-wrap: wrap;
		gap: 0 1em;
		font-size: fz(14);
	}
	li {
		&:not(:first-child) {
			position: relative;
			padding-left: 1.5em;
			&::before {
				content: "〉";
				position: absolute;
				left: 0;
				top: 0.5lh;
				translate: 0 -50%;
			}
		}
	}
	a {
		display: block;
	}
	li:last-child a {
		pointer-events: none;
	}
}

.bl_contSet {
	--grid-column: 2;
	>:not(:first-child) {
		margin-top: 80px;
	}
	>.bl_hdgSet + * {
		margin-top: 0;
	}
	@include mq_not_maxW {
		display: grid;
		grid-template-columns: repeat(3,1fr);
		column-gap: 54px;
	}
	>* {
		grid-column: span var(--grid-column);
	}
}

.bl_anchorLinks {
	$_root: &;
	display: flex;
	flex-wrap: wrap;
	gap: 15px 25px;
	a {
		display: grid;
		grid-template-columns: 1fr auto;
		column-gap: 20px;
		font-weight: 500;
		font-size: fz(18);
		line-height: 1.4;
		color: var(--blue);

		@include action {
			#{$_root} {
				&_icon {
					background-color: var(--blue);
					&:before {
						background-color: #fff;
					}
				}
			}
		}
	}
	&_icon {
		display: grid;
		place-content: center;
		width: 1.4em;
		aspect-ratio: 1;
		border: 1px solid var(--blue);
		border-radius: 50%;
		background-color: #fff;
		transition: background-color var(--transDur);
		&:before {
			content: "";
			width: 12px;
			aspect-ratio: 14/9;
			mask-image: url(../images/icon/icon_arwhead.svg);
			mask-size: contain;
			mask-repeat: no-repeat;
			background-color: var(--blue);
			transition: background-color var(--transDur);
			translate: 0 10%;
		}
	}
}

.bl_btns {
	display: grid;
	gap: 30px;
	&__2col {
		@include mq_not_maxW() {
			grid-template-columns: repeat(2, 1fr);
		}
	}
	&__3col {
		@include mq_not_maxW() {
			grid-template-columns: repeat(3, 1fr);
		}
	}
	&__4col {
		@include mq_not_maxW() {
			grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
		}
	}
	>li {}
}

.bl_imgAndBody {
	--_gap_row: 30px;
	--_gap_col: 40px;
	--_imageMaxW: 40%;
	--grid-column: 3;

	&_inner {
		display: grid;
		gap: var(--_gap_row) var(--_gap_col);
	}
	&__imgRight &_inner {
		@include mq_not_maxW() {
			grid-template-columns: auto fit-content(var(--_imageMaxW));
		}
	}
	&__imgRight &_img {
		@include mq_not_maxW() {
			order: 1;
		}
	}
	&__imgLeft &_inner {
		@include mq_not_maxW() {
			grid-template-columns: fit-content(var(--_imageMaxW)) auto;
		}
	}

	&__imgFloatRight &_inner,
	&__imgFloatLeft &_inner {
		@include mq_not_maxW() {
			display: block;
			margin-bottom: calc(var(--_gap_row) * -1);
			@include cf;
		}
	}
	&__imgFloatRight &_img,
	&__imgFloatLeft &_img {
		@include mq_not_maxW() {
			padding-bottom: var(--_gap_row);
			max-width: var(--_imageMaxW);
		}
	}
	&__imgFloatRight &_img {
		@include mq_not_maxW() {
			float: right;
			margin-left: var(--_gap_col);
		}
	}
	&__imgFloatLeft &_img {
		@include mq_not_maxW() {
			float: left;
			margin-right: var(--_gap_col);
		}
	}
	&__imgFloatRight &_body,
	&__imgFloatLeft &_body {
		@include mq_not_maxW() {
			padding-bottom: var(--_gap_row);
		}
	}
}

.bl_img {
	display: table;
	margin: auto;
	caption-side: bottom;
	&__w100p {
		width: 100%;
		img {
			width: 100%;
		}
	}
	img {
		transition: opacity var(--transDur);
	}
	figcaption {
		display: table-caption;
		margin-top: 15px;
		font-size: fz(14);

		@include mq_maxW() {
			padding-inline: var(--contMargin_hor);
		}
	}
}

.bl_imgGroupList {
	display: grid;
	gap: 40px 24px;

	@include mq_maxW() {
		position: relative;
		left: 50%;
		width: 100vw;
		translate: -50% 0;
	}

	&__1col {
		--grid-column: 3;
	}
	&__2col {
		@include mq_not_maxW() {
			grid-template-columns: repeat(2, 1fr);
		}
	}
	&__3col {
		@include mq_not_maxW() {
			grid-template-columns: repeat(3, 1fr);
		}
	}
	&__4col {
		@include mq_not_maxW() {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	a {

		.bl_img {
			>div {
				position: relative;
				overflow: hidden;

				img {
					transition: transform var(--transDur);
				}

				&::before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: #000;
					opacity: 0;
					visibility: hidden;
					transition-property: opacity,visibility;
					transition-duration: var(--transDur);
					z-index: 2;
				}
			}
		}

		@include action {
			.bl_img {
				>div {
					&:before {
						opacity: 0.3;
						visibility: visible;
					}
					img {
						transform: scale(1.02);
					}
				}
			}
		}
	}
}

.bl_imgGroup {
	.bl_img {
		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}
	&_body {
		@include mq_maxW() {
			padding-inline: var(--contMargin_hor);
		}
	}
	&_title {
		font-size: fz(20);
		font-weight: bold;
		&:not(:last-child) {
			margin-bottom: 15px;
		}
	}
}

.bl_wysiwyg {
	h2 {
		@include hdgLg;
	}
	h3 {
		@include hdgMd;
	}
	h4 {
		@include hdgSm;
	}
	a {
		color: var(--blue);
		@include addLinkIcon;
		&::after {
			margin-left: 5px;
			translate: 0 4px;
		}
		>span {
			text-decoration: underline;
			text-decoration-color: currentColor;
			text-underline-offset: 2px;
			transition: text-decoration-color var(--transDur);
		}
		@include action {
			>span {
				text-decoration-color: transparent;
			}
		}
	}
	ol,
	ul {
		li {
			&:not(:first-child) {
				margin-top: 0.5em;
			}
			ol,
			ul {
				margin-top: 0.5em;
			}
		}
	}
	ol {
		counter-reset: liCounter;
		>li {
			position: relative;
			margin-left: 1em;
			&::before {
				content: counter(liCounter)".";
				counter-increment: liCounter;
				position: absolute;
				right: 100%;
				top: 0;
				margin-right: 3px;
				white-space: nowrap;
			}
		}
	}
	ul {
		>li {
			position: relative;
			padding-left: 1em;
			&::before {
				content: "";
				position: absolute;
				left: 0;
				top: 0.5lh;
				border-radius: 50%;
				width: 0.5em;
				aspect-ratio: 1;
				background-color: currentColor;
				translate: 0 -50%;
			}
		}
	}
	table {
		border-collapse: separate;
		border-spacing: 10px 0;
		table-layout: fixed;
		width: 100%;
	}
	th,
	td {
		padding: 15px 20px;
	}
	th {
		background-color: var(--gray);
		border: 2px solid #fff;
		font-weight: 600;
		font-size: fz(22);
		line-height: calc(36/22);
		letter-spacing: 0.05em;
		color: var(--blue);
	}
	td {
		border-bottom: 1px solid #969696;
		line-height: calc(28/16);
	}
}

.bl_table {
	--grid-column: 3;
	margin-inline: -10px;
	width: calc(100% + 20px);
	&__spScroll {
		@include mq_maxW() {
			margin-inline: calc((var(--htmlW) - (670 / var(--designW) * var(--htmlW))) / -2);
			// 横スクロール＝overflow:autoで下ボーダーが切れて見えることがあるため、1pxのpadding-bottomを確保
			padding: 0 calc((var(--htmlW) - (670 / var(--designW) * var(--htmlW))) / 2 - 10px) 1px;
			width: auto;
		}
		table {
			@include mq_maxW() {
				width: 200%;
			}
		}
	}
}

.bl_video {
	display: table;
	width: 100%;
	caption-side: bottom;
	iframe {
		width: 100%;
		height: auto;
		aspect-ratio: 16 / 9;
	}
	figcaption {
		display: table-caption;
		margin-top: 15px;
		font-size: fz(14);
	}
}

.bl_videoGroupList {
	display: grid;
	gap: 40px 24px;
	&__2col {
		@include mq_not_maxW() {
			grid-template-columns: repeat(2, 1fr);
		}
	}
	&__3col {
		@include mq_not_maxW() {
			grid-template-columns: repeat(3, 1fr);
		}
	}
	&__4col {
		@include mq_not_maxW() {
			grid-template-columns: repeat(4, 1fr);
		}
	}
}

.bl_videoGroup {
	.bl_video {
		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}
	&_title {
		font-size: fz(20);
		font-weight: bold;
		&:not(:last-child) {
			margin-bottom: 15px;
		}
	}
}

.bl_dataTable {
	display: grid;
	grid-template-columns: minmax(200px, max-content) 1fr;
	gap: 0 2em;
	@include mq_maxW() {
		grid-template-columns: minmax(100px, max-content) 1fr;
	}
	&_row {
		display: grid;
		grid-template-columns: subgrid;
		grid-column: span 2;
		align-items: center;
		border-bottom: 2px solid;
		padding: 20px;
		&:nth-child(odd) {
			background-color: #ddd;
		}
	}
	dt {
		font-weight: bold;
	}
}

.bl_accordionList {
	display: grid;
	gap: 30px;
}

.bl_accordion {
	border: 2px solid;
	&_head {
		display: grid;
		grid-template-columns: 1fr auto;
		gap: 40px;
		align-items: center;
		padding: 20px 30px;
		cursor: pointer;
		&::-webkit-details-marker {
			display: none;
		}
		.icon {
			position: relative;
			width: 20px;
			height: 2px;
			background-color: currentColor;
			&::before {
				content: "";
				position: absolute;
				inset: 0;
				background-color: inherit;
				rotate: 90deg;
			}
		}
	}
	&[open] &_head {
		.icon {
			&::before {
				opacity: 0;
			}
		}
	}
	&_body {
		overflow: hidden;
		>div {
			padding: 0 30px 20px;
		}
	}
}

.bl_dialog {
	opacity: 0;
	visibility: hidden;
	display: block;
	overflow-y: auto;
	overscroll-behavior: contain;
	transition-property: opacity, visibility, overlay;
	transition-duration: var(--transDur);
	transition-timing-function: var(--transEasing);
	transition-behavior: allow-discrete;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
	&[open] {
		opacity: 1;
		visibility: visible;
	}
	&::backdrop {
		display: none;
	}
	&_backdrop {
		height: calc(100% + 1px);
		// 内部（コンテンツ）のスクロールバーを出したい場合は下記コメントアウトを外す（ただし二重になる）
		// overflow: auto;
	}
	&_inner {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 40px;
		min-height: 100%;
		background-color: rgb(0 0 0 / 0.4);
	}
	&_body {
		position: relative;
		padding: 40px;
		background-color: #fff;
	}
	&_closeBtn {
		position: absolute;
		right: 0;
		top: 0;
		width: 32px;
		height: 32px;
		background-color: #000;
		&::before,
		&::after {
			content: "";
			position: absolute;
			left: 50%;
			top: 50%;
			border-top: 2px solid #fff;
			width: 16px;
			translate: -50% -50%;
		}
		&::before {
			rotate: -45deg;
		}
		&::after {
			rotate: 45deg;
		}
	}
}

.bl_news {
	>ul,
	>ol {
		display: grid;
		grid-template-columns: auto 1fr;
		>li {
			display: grid;
			grid-template-columns: subgrid;
			grid-column: span 2;
			padding-bottom: 15px;
			border-bottom: 1px solid currentColor;
			&:not(:first-child) {
				margin-top: 20px;
			}
			>article {
				display: inherit;
				grid-template-columns: inherit;
				grid-column: inherit;
			}
		}
	}
	&_head {
		display: inherit;
		grid-template-columns: inherit;
		grid-column: inherit;
		align-items: center;
		column-gap: 16px;
		time {
			display: inline-block;
			font-weight: 500;
			@include mq_not_maxW {
				font-size: fz(25);
			}
			@include mq_maxW {
				font-size: fz(22);
			}
		}
	}
	&_body {
		grid-column: inherit;
		@include mq_not_maxW {
			font-size: fz(20);
		}
		@include mq_maxW {
			font-size: fz(16);
		}
		>a {
			@include addLinedLink(".bl_news_hdg");
			// @include action {
			// 	.bl_news_hdg {
			// 		text-decoration-color: currentColor;
			// 	}
			// }
		}
	}
	&_hdg {
		// text-decoration: underline;
		// text-underline-offset: 2px;
		// text-decoration-color: transparent;
		// transition: text-decoration-color var(--transDur);
	}
	&_category {
		>ul {
			display: flex;
			gap: 10px;
		}
		&_item {
			display: inline-block;
			width: fit-content;
			font-size: fz(15);
			line-height: 1;
			color: #fff;
			@include mq_not_maxW {
				padding: 6px;
			}
			@include mq_maxW {
				padding: 3px 6px;
			}
			&.__blue {
				background-color: var(--blue);
			}
			&.__pink {
				background-color: #e19bd2;
			}
			&.__gray {
				background-color: #494949;
			}
			&.__lightblue {
				background-color: #23afe1;
			}
		}
	}
}

.bl_pageTtl {
	margin-bottom: 6px;
	@include mq_maxW() {
		margin-bottom: 65px;
	}
	&_inner {
		position: relative;
		display: flex;
		align-items: center;
		min-height: 160px;
		padding: 40px;

		@include mq_not_maxW {
			width: var(--contW);
			margin-inline: auto;
		}
		@include mq_maxW() {
			padding: 20px;
			padding-right: 40%;
		}
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(to right, #00205f 49.55%, rgba(0, 32, 95, 0.35) 63.68%, rgba(0, 32, 95, 0) 72.31%);
			z-index: 2;
		}
	}
	&_txt {
		position: relative;
		font-size: fz(32);
		letter-spacing: 0.02em;
		color: #fff;
		z-index: 3;
		@include mq_maxW {
			font-size: fz(18);
		}
	}
	&_img {
		position: absolute;
		top: 0;
		right: 0;
		width: 54%;
		height: 100%;
		z-index: 1;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

.bl_linkList {
	display: grid;
	grid-template-columns: minmax(0,1fr);
	row-gap: 10px;
}

.bl_rollModel {
	--grid-column: 3;
	counter-reset: roll-counter;
	display: grid;
	grid-template-columns: minmax(0,1fr);
	row-gap: 50px;
	@include mq_maxW() {
		position: relative;
		left: 50%;
		width: 100vw;
		translate: -50% 0;
	}
	&_item {
		position: relative;
		counter-increment: roll-counter;
		padding: 40px 50px;
		background-color: var(--gray);
		@include mq_maxW() {
			padding: 40px var(--contMargin_hor);
		}
	}
	&_head {
	}
	&_hdg {
		position: relative;
		display: grid;
		grid-template-columns: auto 1fr;
		@include mq_maxW() {
			display: flex;
			flex-wrap: wrap;
		}
		>span {
			box-shadow: 0px 5px 1px rgba(0, 0, 0, 0.4);
		}
		.main {
			position: relative;
			padding: 10px 30px;
			background-color: #fff;
			font-weight: bold;
			font-size: fz(34);
			letter-spacing: 0.04em;
			color: var(--blue);
			@include mq_maxW() {
				width: 100%;
				padding: 5px 24px;
				font-size: fz(24);
				letter-spacing: normal;
			}
		}
		.sub {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 20px 30px;
			background-color: var(--blue);
			font-weight: 600;
			font-size: fz(19);
			color: #fff;

			@include mq_maxW() {
				padding: 10px 20px;
				font-size: fz(16);
			}
			&:after {
				content: counter(roll-counter);
			}
		}
	}
	&_graduated {
		position: absolute;
		top: 0;
		right: 0;
		padding: 0 20px;
		width: fit-content;
		height: auto;
		background-color: var(--blue);
		font-weight: 600;
		letter-spacing: 0.04em;
		color: #fff;

		>span {
			display: inline-block;
			margin-block: var(--negLeading);
		}
	}
	&_body {
		margin-top: 40px;
		display: grid;
		grid-template-columns: 1fr 42% auto;
		column-gap: 30px;
		@include mq_maxW(1024px) {
			grid-template-columns: minmax(0,1fr);
			margin-top: 15px;
		}
	}
	&_teacher {
		margin-top: 30px;
		font-weight: 600;
		font-size: fz(18);
		color: var(--blue);

		@include mq_maxW() {
			margin-top: 15px;
		}

		>span {
			display: block;
		}
	}
	&_flow {
		@include mq_maxW(1024px) {
			margin-top: 10px;
		}

		&_item {
			position: relative;
			width: 100%;
			font-weight: 600;
			font-size: fz(18);
			letter-spacing: 0.05em;
			line-height: calc(26/18);
			color: var(--blue);
			text-align: center;

			&__typeA {
				padding: 4px 6px 7px;
				background-color: var(--lightblue);

				&:not(:first-child) {
					margin-top: 40px;
					&:before {
						content: "";
						position: absolute;
						display: inline-block;
						left: 50%;
						bottom: 100%;
						width: 35px;
						height: 40px;
						background-image: url(../images/icon/icon_arw.svg);
						background-size: 35px 35px;
						background-position: top center;
						background-repeat: no-repeat;
						translate: -50% 0;
					}
				}
			}

			&__typeB {
				padding: 3px 6px 6px;
				border: 2px solid var(--blue);
				border-radius: 14px;

				&:not(:first-child) {
					margin-top: 16px;
					&:before {
						content: "";
						position: absolute;
						display: inline-block;
						left: 50%;
						bottom: 100%;
						width: 4px;
						height: 17.5px;
						background-color: var(--blue);
						translate: -50% 0;
					}
				}
			}
		}
	}
	&_img {
		@include mq_maxW(1024px) {
			margin-top: 20px;
		}
		img {
			display: block;
			margin: auto;
		}
	}
}

.bl_teacherList {
	--grid-column: 3;
	$_root: '.bl_teacherList';
	display: grid;
	@include mq_not_maxW {
		// grid-template-columns: repeat(auto-fill, minmax(312px, 1fr));
		// column-gap: min(11%, calc(100% - 624px));
		grid-template-columns: repeat(3,minmax(0,1fr));
		column-gap: 11%;
		justify-content: space-between;
		row-gap: 48px;
	}
	@include mq_maxW {
		grid-template-columns: repeat(2,1fr);
		column-gap: 34px;
		row-gap: 28px;
	}
	>li {
		>a {
			@include action {
				#{$_root} {
					&_thumb {
						img {
							transform: scale(1.02);
						}
						&::before {
							opacity: 0.3;
							visibility: visible;
						}
					}
					&_hdg {
						color: var(--hover);
					}
				}
			}
		}
	}
	&_thumb {
		position: relative;
		width: 100%;
		height: auto;
		overflow: hidden;
		@include mq_not_maxW {
			aspect-ratio: 1.04;
		}
		@include mq_maxW {
			aspect-ratio: 29/30;
		}
		img {
			position: relative;
			width: 100%;
			height: 100%;
			object-fit: cover;
			transform-origin: center;
			transition: transform var(--transDur);
			z-index: 1;
		}
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: #000;
			opacity: 0;
			visibility: hidden;
			transition-property: opacity,visibility;
			transition-duration: var(--transDur);
			z-index: 2;
		}
	}
	&_label {
		@include mq_not_maxW {
			margin-top: 18px;
		}
		@include mq_maxW {
			margin-top: 12px;
		}
	}
	&_hdg {
		font-weight: 500;
		color: var(--blue);
		transition: color var(--transDur);
		&_main {
			@include mq_not_maxW {
				font-size: fz(30);
			}
			@include mq_maxW {
				font-size: fz(17);
			}
		}
		&_sub {
			margin-left: 0.5em;
			@include mq_not_maxW {
				font-size: fz(22);
			}
			@include mq_maxW {
				font-size: fz(13);
			}
		}
	}
	&_link {
		margin-top: 20px;
		>ul {
			li {
				margin-top: 9px;

				.el_link__blank {
					&[href*="www.youtube.com"] {
						&::after {
							background-image: url(https://placehold.jp/200x200.png);
						}
					}
				}
			}
		}
	}
}

.bl_introductionList {
	--grid-column: 3;
	display: grid;
	grid-template-columns: minmax(0,1fr);
	row-gap: 50px;
}

.bl_introduction {
	display: grid;
	@include mq_not_maxW {
		grid-template-columns: 30% 1fr;
		column-gap: 50px;
	}
	@include mq_maxW {
		grid-template-columns: minmax(0,1fr);
		row-gap: 20px;
	}
	&_img {
		height: auto;
		aspect-ratio: 1;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	&_wrapper {
		@include mq_not_maxW {
			// grid-column: span 2;
		}
		@include mq_maxW {}
	}
	&_txt {
		line-height: 1.8;
	}
	&_name {
		margin-bottom: 6px;
		font-weight: 600;
		color: var(--blue);
		@include mq_maxW() {
			margin-block: 20px 0;
		}
		.main {
			font-size: fz(28);
		}
		.sub {
			margin-left: 0.5em;
			font-size: fz(22);
		}
	}
	&_major {
		line-height: 2;
		@include mq_maxW() {
			margin-top: 10px;
		}
	}
	&_link {
		margin-top: 7px;
		>ul {
			display: grid;
			grid-template-columns: minmax(0,1fr);
			row-gap: 10px;
		}
	}
}

.bl_boxedTxtList {
	--grid-column: 3;
	display: grid;
	grid-template-columns: minmax(0,1fr);
	row-gap: 20px;
}

.bl_boxedTxt {
	padding: 40px 60px;
	background-color: var(--gray);
	>:not(:first-child) {
		margin-top: 30px;
	}
	>.bl_hdgSet + * {
		margin-top: 0;
	}

	@include mq_maxW() {
		padding: 40px 20px;
	}
}


.bl_flowList {
	$_root: &;
	--grid-column: 3;
	counter-reset: flow-counter;
	display: grid;
	grid-template-columns: minmax(0,1fr);
	row-gap: 50px;
	padding: 40px 50px;
	background-color: var(--gray);

	@include mq_maxW() {
		row-gap: 75px;
		padding: 20px;
	}
	>li {
		&:not(:last-child) {
			#{$_root} {
				&_item {
					&:after {
						content: "";
						position: absolute;
						display: inline-block;
						bottom: -10px;
						left: 50%;
						width: 37px;
						height: 37px;
						background-image: url(../images/icon/icon_arw.svg);
						background-size: contain;
						background-repeat: no-repeat;
						translate: -50% 100%;
					}
				}
			}
		}
	}
	&_item {
		position: relative;
		counter-increment: flow-counter;

		@include mq_not_maxW() {
			display: grid;
			grid-template-columns: 125px 1fr;
		}
	}
	&_head {
		@include mq_not_maxW {
			display: grid;
			grid-template-columns: subgrid;
			grid-column: span 2;
		}
	}
	&_num {
		display: grid;
		place-content: center;
		background-color: var(--blue);
		color: #fff;

		@include mq_maxW() {
			width: 112px;
		}
		>span {
			font-weight: 500;
			font-size: fz(22);
			line-height: 1.4;

			@include mq_maxW() {
				font-size: fz(19);
			}
			&:after {
				content: counter(flow-counter);
				margin-left: 0.1em;
				font-size: fz(32);

				@include mq_maxW() {
					font-size: fz(30);
				}
			}
		}
	}
	&_hdg {
		padding: 5px 25px;
		border-bottom: 1px solid var(--blue);
		font-weight: 600;
		font-size: fz(22);
		color: var(--blue);

		@include mq_maxW() {
			padding-inline: 0;
			font-size: fz(18);
		}
	}
	&_body {
		padding-top: 10px;

		@include mq_not_maxW {
			display: grid;
			grid-template-columns: subgrid;
			grid-column: 2/3;
			padding-inline: 30px;
		}
	}
	&__fromZero {
		counter-reset: flow-counter -1;
	}
}

.bl_faq {
	$_root: &;
	display: grid;
	grid-template-columns: minmax(0,1fr);
	row-gap: 50px;
	&_item {
		display: grid;
		grid-template-columns: auto 1fr;
		column-gap: 10px;
	}
	&_q {
		display: grid;
		grid-template-columns: subgrid;
		grid-column: span 2;
		&:before {
			content: "Q.";
			font-weight: 600;
			font-size: fz(36);
			letter-spacing: 0.1em;
			color: var(--blue);
		}
		>p {
			padding-block: 15px 10px;
			border-bottom: 1px solid var(--blue);
			font-weight: 600;
			font-size: fz(22);
			color: var(--blue);

			@include mq_maxW() {
				padding-top: 20px;
				font-size: fz(18);
			}
		}
	}
	&_a {
		display: grid;
		grid-template-columns: subgrid;
		grid-column: span 2;
		&:before {
			content: "A.";
			font-weight: 600;
			font-size: fz(36);
			letter-spacing: 0.1em;
			color: var(--lightblue);
		}
		>.bl_wysiwyg {
			margin-top: 20px;
		}
	}
}

.bl_iconLinkList {
	--grid-column: 3;
	display: grid;
	grid-template-columns: repeat(3,1fr);
	gap: 20px;
	padding: 30px 40px;
	background-color: var(--gray);

	@include mq_maxW {
		position: relative;
		grid-template-columns: minmax(0,1fr);
		left: 50%;
		width: 100vw;
		padding: 30px var(--contMargin_hor);
		translate: -50% 0;
	}
	>li {
		display: grid;
	}
}

.bl_iconLink {
	$_root: &;
	display: grid;
	>a {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 15px;
		padding: 30px;
		background-color: #fff;
		box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);

		&:before {
			content: "";
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			background-color: var(--blue);
			// transform: scaleX(0);
			scale: 0 100%;
			transition: scale var(--transDur);
		}

		@include action {
			&:before {
				// animation: scaleX 0.3s both ease-in-out;
				scale: 100% 100%;
			}
			#{$_root} {
				&_hdg {
					color: #fff;
				}
			}
		}
	}
	&_icon {
		position: relative;
		width: 70px;
		height: 70px;
		overflow: hidden;
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
	&_hdg {
		position: relative;
		font-weight: 600;
		font-size: fz(18);
		letter-spacing: 0.05em;
		color: var(--blue);
		transition: color var(--transDur);
	}
}
