/**
 * Element
 */

.el_hdgLg {
	@include hdgLg;
}
.el_hdgMd {
	@include hdgMd;
}
.el_hdgSm {
	@include hdgSm;
}

.el_btn {
	display: grid;
	place-items: center;
	border: 1px solid #333;
	padding: 16px 48px;
	width: 100%;
	height: 100%;
	background-color: #333;
	color: #fff;
	@include addLinkIcon('>span');
	@include action {
		background-color: #fff;
		color: #333;
	}
	>span {
		&::after {
			translate: 0 4px;
		}
		>span {
			margin-right: 5px;
		}
	}
}

.el_iconArw {
	position: relative;
	background-color: var(--gray);
	&::before {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		width: 50%;
		height: calc(2 / 3 * 100%);
		background-color: var(--blue);
		clip-path: polygon(0% 0%, 33% 0%, 100% 50%, 33% 100%, 0% 100%, 67% 50%);
		transform: translate(-50%,-50%);
	}
}

.el_link {
	position: relative;
	&__default {
		display: grid;
		grid-template-columns: 1fr auto;
		width: 100%;
		// background-image: linear-gradient(transparent 15%, var(--blue) 15%, var(--blue) 90%, transparent 90%);
		background-image: linear-gradient(var(--lightblue) 0%, var(--lightblue) 100%);
		// line-height: 1.3;
		font-weight: bold;
		color: var(--blue);
		// transition: color 0.3s;
		@include mq_not_maxW {
			padding: 10px 15px 10px 20px;
			font-size: fz(22);
		}
		@include mq_maxW {
			padding: 5px 12px;
			font-size: fz(18);
		}
		>span {
			position: relative;
			display: inline-block;
			z-index: 2;
		}
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			// background-image: linear-gradient(transparent 15%, var(--lightblue) 15%, var(--lightblue) 90%, transparent 90%);
			background-image: linear-gradient(var(--skyblue) 0%, var(--skyblue) 100%);
			transform-origin: center;
			// transform: scaleX(0);
			scale: 0 100%;
			transition: scale var(--transDur);
			z-index: 1;
		}
		&::after {
			content: "";
			position: relative;
			display: inline-block;
			height: auto;
			aspect-ratio: 15/8;
			margin-top: 0.5lh;
			mask-image: url(../images/icon/icon_doubleArw.svg);
			mask-size: contain;
			mask-repeat: no-repeat;
			background-color: currentColor;
			translate: 0 -50%;
			z-index: 3;
			@include mq_not_maxW {
				margin-left: 15px;
				width: 32px;
			}
			@include mq_maxW {
				margin-left: 11px;
				width: 38px;
			}
		}
		@include action {
			// color: var(--blue);
			&::before {
				// animation: scaleX 0.3s both ease-in-out;
				scale: 100% 100%;
			}
		}
	}
	&__file {
		--fileName: "";
		display: inline-block;
		font-weight: 600;
		color: var(--blue);
		@include addLinedLink('>span');
		@include mq_not_maxW {
			padding-right: 54px;
			font-size: fz(22);
			line-height: 2.2;
		}
		@include mq_maxW {
			font-size: fz(18);
		}
		&:after {
			content: "";
			position: relative;
			display: inline-block;
			height: auto;
			aspect-ratio: 3/4;
			bottom: 0.25lh;
			margin-top: calc(100% * -4 * 3);
			background-image: url(../images/icon/icon_file.svg);
			background-repeat: no-repeat;
			background-size: contain;
			translate: 0 50%;
			@include mq_not_maxW {
				margin-left: 18px;
				width: 36px;
			}
			@include mq_maxW {
				margin-left: 9px;
				width: 18px;
			}
		}
		>span {
			position: relative;
			&:after {
				content: "[" var(--fileName) "]";
				margin-left: 0.5em;
				@include mq_not_maxW {
					font-size: fz(16);
				}
				@include mq_maxW {
					font-size: fz(14);
				}
			}
		}
		&[href$=".pdf"] {
			--fileName: "PDF";
		}
		&[href$=".doc"],
		&[href$=".docx"],
		&[href$=".docm"],
		&[href$=".dotx"],
		&[href$=".dotm"] {
			--fileName: "DOC";
		}
		&[href$=".xls"],
		&[href$=".xlsx"],
		&[href$=".xlt"],
		&[href$=".xml"],
		&[href$=".xlsm"],
		&[href$=".csv"] {
			--fileName: "XLS";
		}
		&[href$=".ppt"],
		&[href$=".pptx"],
		&[href$=".pptm"] {
			--fileName: "PPT";
		}
		&[href$=".zip"] {
			--fileName: "ZIP";
		}
	}
	&__blank {
		display: block;
		font-weight: 500;
		color: var(--blue);
		@include addLinedLink('>span');
		@include mq_not_maxW {
			padding-left: 68px;
			font-size: fz(16);
			line-height: 2.6;
		}
		@include mq_maxW {
			padding-left: 34px;
			min-height: 21px;
			font-size: fz(14);
		}
		>span {
			position: relative;
		}
		&::after {
			content: "";
			position: absolute;
			top: 0.5lh;
			left: 0;
			width: 56px;
			height: 42px;
			background-image: url(../images/icon/icon_window.svg);
			background-repeat: no-repeat;
			background-size: contain;
			translate: 0 -50%;
			@include mq_not_maxW {
				width: 56px;
				height: 42px;
			}
			@include mq_maxW {
				width: 28px;
				height: 21px;
			}
		}
	}
}
