/**
 * Header
 */

.ly_header {
	position: fixed;
	padding-block: 16px;
	width: 100%;
	background-color: #fff;
	transition: transform var(--transDur);
	z-index: 10000;
	&_inner {
		margin: auto;
		width: var(--contW);
	}
	&_logo {
		flex-shrink: 0;
		height: auto;
		aspect-ratio: 74/23;
		overflow: hidden;
		@include mq_not_maxW {
			width: 22.5%;
		}
		@include mq_maxW {
			width: 35%;
		}
		>a {
			display: block;
			width: 100%;
			height: 100%;
			transition: opacity var(--transDur);
			@include action {
				opacity: 0.6;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}
	&_head {
		position: relative;
		width: 100%;
		z-index: 1;
		&_wrapper {
			@include mq_not_maxW {
				display: flex;
				align-items: center;
				height: fit-content;
			}
			@include mq_maxW {
				display: grid;
				grid-template-columns: 1fr auto auto;
				column-gap: 8px;
				justify-content: space-between;
				align-items: center;
				width: fit-content;
			}
		}
		&_lang {
			--baseColor: var(--gray);
			@include mq_not_maxW {
				margin-left: 14px;
			}
			@include mq_maxW {
				grid-column: 2 / 3;
				grid-row: 1 / 2;
				width: fit-content;
			}
			>a {
				align-items: center;
				gap: 13px;
				color: var(--blue);
			}
		}
		&_exam {
			--baseColor: var(--blue);
			@include mq_maxW {
				grid-column: span 3;
				grid-row: 2 / 3;
			}
			>a {
				align-items: center;
				gap: 13px;
				color: #fff;
				&::after {
					content: "";
					position: relative;
					display: inline-block;
					height: auto;
					aspect-ratio: 40/21;
					mask-image: url(../images/icon/icon_doubleArw.svg);
					mask-size: contain;
					mask-repeat: no-repeat;
					background-color: currentColor;
					z-index: 3;
					@include mq_not_maxW {
						width: min(32px, calc(32 / (var(--designW) + var(--scrollbarW)) * 100vw));
					}
					@include mq_maxW {
						width: 20px;
					}
				}
			}
		}
		&_fund {
			--baseColor: var(--gray);
			@include mq_not_maxW {
				margin-left: 14px;
			}
			@include mq_maxW {
				grid-column: 1 / 2;
				grid-row: 1 / 2;
			}
			>a {
				align-items: flex-end;
				gap: 10px;
				color: var(--blue);
				&::after {
					content: "";
					position: relative;
					display: inline-block;
					height: auto;
					aspect-ratio: 39/29;
					mask-image: url(../images/icon/icon_fund.svg);
					mask-size: contain;
					mask-repeat: no-repeat;
					background-color: currentColor;
					z-index: 3;
					@include mq_not_maxW {
						width: min(32px, calc(32 / (var(--designW) + var(--scrollbarW)) * 100vw));
					}
					@include mq_maxW {
						width: 24px;
					}
				}
			}
		}
		&_lang,
		&_exam,
		&_fund {
			>a {
				position: relative;
				display: flex;
				background-image: linear-gradient(transparent 15%, var(--baseColor) 15%, var(--baseColor) 90%, transparent 90%);
				font-weight: 500;
				transition: color 0.3s;
				overflow: hidden;
				@include mq_not_maxW {
					padding: 4px 12px;
					font-size: fz_vw_clamp(18);
				}
				@include mq_maxW {
					padding: 3px 7.5px;
					font-size: fz(14);
				}
				>span {
					position: relative;
					z-index: 2;
				}
				&::before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-image: linear-gradient(transparent 15%, var(--lightblue) 15%, var(--lightblue) 90%, transparent 90%);
					transform-origin: center;
					// transform: scaleX(0);
					scale: 0 100%;
					transition: scale var(--transDur);
					z-index: 1;
				}
				@include action {
					color: var(--blue);
					&::before {
						// animation: scaleX 0.3s both ease-in-out;
						scale: 100% 100%;
					}
				}
			}
		}
		&_nav {
			display: grid;
			justify-content: flex-end;
			text-align: right;
			color: var(--blue);
			@include mq_maxW {
				margin-top: 7px;
			}
			&_hdg {
				@include mq_not_maxW {
					margin-top: -1em;
					font-size: fz_vw_clamp(34);
				}
				@include mq_maxW {
					font-size: fz(18);
					letter-spacing: 0.03em;
					line-height: 1.4;
				}

				.is_english & {
					@include mq_not_maxW {
						font-size: fz_vw_clamp(26);
					}
				}
			}
			>ul {
				display: flex;
				align-items: center;
				gap: 2em;
				width: fit-content;
				border-top: 1px solid currentColor;
				@include mq_not_maxW {
					font-size: fz_vw_clamp(16);
				}
				@include mq_maxW {}
				>li {
					font-weight: 500;
					>a {
						position: relative;
						@include addLinedLink;
					}
				}
			}
		}
	}
	&_info {
		display: flex;
		justify-content: space-between;
		@include mq_maxW {
			align-items: center;
		}
	}
	&_openBtn {
		position: relative;
		display: flex;
		align-items: center;
		color: var(--blue);
		transition: color var(--transDur);
		@include mq_not_maxW {
			width: 43px;
			height: 22px;
			margin-left: 30px;
		}
		@include mq_maxW {
			grid-column: 3 / 4;
			grid-row: 1 / 2;
			width: 27px;
			height: 12px;
		}
		&::before,
		&::after {
			content: "";
			position: absolute;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 3px;
			border-radius: 1000px;
			background-color: currentColor;
			transform: perspective(0);
		}
		&::before {
			top: 0;
		}
		&::after {
			bottom: 0;
		}
		>span {
			display: inline-block;
			width: 100%;
			height: 3px;
			border-radius: 1000px;
			background-color: currentColor;
			transform: perspective(0);
		}
		@include action {
			color: var(--lightblue);
		}
	}
	&_body {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100svh;
		background-color: #fff;
		overflow: hidden;
		transform: translateY(-100%);
		transition: transform var(--transDur) var(--transEasing);
		z-index: 10000;
		@include mq_maxW {
			overflow-y: auto;
		}
		&_bg {
			position: absolute;
			height: auto;
			aspect-ratio: 1;
			z-index: 1;
			@include mq_not_maxW {
				top: 10px;
				right: 10px;
				width: 44.3%;
			}
			@include mq_maxW {
				top: 60px;
				left: 50%;
				width: 90%;
				transform: translateX(-25%);
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		&_header {
			position: relative;
			z-index: 50;
			@include mq_not_maxW {
				padding-block: 68px 24px;
			}
			@include mq_maxW {
				padding-block: 20px 35px;
			}
			&_inner {
				display: flex;
				@include mq_not_maxW {
					justify-content: space-between;
				}
				@include mq_maxW {
					flex-direction: column;
				}
				.ly_header_logo {
					height: fit-content;
				}
			}
			&_txtArea {
				@include mq_maxW {
					margin-top: 40px;
				}
			}
			&_link {
				display: flex;
				margin-top: 5px;
				@include mq_not_maxW {
					justify-content: flex-end;
				}
				@include mq_maxW {}
				>a {
					position: relative;
					display: flex;
					align-items: center;
					width: fit-content;
					background-color: var(--blue);
					font-weight: 500;
					color: #fff;
					transition: color 0.3s;
					overflow: hidden;
					@include mq_not_maxW {
						gap: min(14px, calc(14 / (var(--designW) + var(--scrollbarW)) * 100vw));
						padding-inline: min(16px, calc(16 / (var(--designW) + var(--scrollbarW)) * 100vw));
						font-size: fz_vw_clamp(22);
					}
					@include mq_maxW {
						gap: 20px;
						padding-inline: 12px;
						font-size: fz(21);
					}
					>span {
						position: relative;
						z-index: 2;
					}
					&::before {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background-color: var(--lightblue);
						transform-origin: center;
						// transform: scaleX(0);
						scale: 0 100%;
						transition: scale var(--transDur);
						z-index: 1;
					}
					&::after {
						content: "";
						position: relative;
						display: inline-block;
						height: auto;
						aspect-ratio: 40/21;
						mask-image: url(../images/icon/icon_doubleArw.svg);
						mask-size: contain;
						mask-repeat: no-repeat;
						background-color: currentColor;
						z-index: 3;
						@include mq_not_maxW {
							width: min(32px, calc(32 / (var(--designW) + var(--scrollbarW)) * 100vw));
						}
						@include mq_maxW {
							width: 30px;
						}
					}
					@include action {
						color: var(--blue);
						&::before {
							// animation: scaleX 0.3s both ease-in-out;
							scale: 100% 100%;
						}
					}
				}
			}
		}
		&_hdg {
			>span {
				font-weight: 500;
				color: var(--blue);
				@include mq_not_maxW {
					padding-block: 10px;
					background-image: linear-gradient(transparent 15%, var(--lightblue) 15%, var(--lightblue) 90%, transparent 90%);
					font-size: fz_vw_clamp(48);
				}
				@include mq_maxW {
					display: grid;
				}
			}
			&_main {
				@include mq_not_maxW {
					padding-inline: min(14px, calc(14 / (var(--designW) + var(--scrollbarW)) * 100vw));
					font-size: fz_vw_clamp(48);
				}
				@include mq_maxW {
					display: inline-block;
					padding: 2px 8px;
					width: fit-content;
					background-image: linear-gradient(transparent 15%, var(--lightblue) 15%, var(--lightblue) 90%, transparent 90%);
					font-size: fz(29);
				}
			}
			&_sub {
				@include mq_not_maxW {
					padding-inline: min(10px, calc(10 / (var(--designW) + var(--scrollbarW)) * 100vw));
					font-size: fz_vw_clamp(32);
				}
				@include mq_maxW {
					display: inline-block;
					padding: 2px 8px;
					width: fit-content;
					background-image: linear-gradient(transparent 15%, var(--lightblue) 15%, var(--lightblue) 90%, transparent 90%);
					font-size: fz(19);
				}
			}
		}
		&_copy {
			>span {
				background-image: linear-gradient(transparent 15%, #ebebeb 15%, #ebebeb 90%, transparent 90%);
				color: var(--blue);
				@include mq_not_maxW {
					padding: 3px 18px;
					font-size: fz_vw_clamp(44);
				}
				@include mq_maxW {
					padding: 3px 10px;
					font-size: fz(22);
				}
			}
		}
		&_cont {
			position: relative;
			overflow-y: auto;
			z-index: 2;
			&_inner {
				@include mq_not_maxW {
					display: grid;
					grid-template-columns: repeat(3, 1fr);
					column-gap: 5%;
				}
			}
		}
		&_nav {
			display: grid;
			// grid-template-columns: subgrid;
			grid-column: span 2;
			>ul {
				@include mq_not_maxW {
					column-count: 2;
					column-gap: calc(min(calc(1200 / 1360) * 100vw, 1200px) * 5 / 100);
				}
				@include mq_maxW {}
			}
			&_item {
				&:not(:first-child) {
					margin-top: 24px;
				}
				>p {
					margin-bottom: 15px;
					border-bottom: 2px solid #5bbde5;
					font-weight: 500;
					color: var(--blue);
					@include mq_not_maxW {
						font-size: fz_vw_clamp(26);
					}
					@include mq_maxW {
						font-size: fz(26);
					}
					>a {
					}
				}
				&:not(:has(>p)) {
					@include mq_not_maxW {
						padding-top: 16px;
					}
				}
				>ul {
					letter-spacing: 0.02em;
					@include mq_not_maxW {
						font-size: fz_vw_clamp(18);
					}
					@include mq_maxW {
						font-size: fz(18);
					}
					a {
						position: relative;
						padding-bottom: 4px;
						@include addLinedLink;
						&::before {
							background-color: var(--blue);
						}
					}
				}
			}
		}
		&_subNav {
			display: grid;
			align-items: flex-end;
			@include mq_maxW {
				margin-top: 35px;
			}
			>ul {
				@include mq_not_maxW {
					display: flex;
					flex-direction: column;
					gap: 30px;
				}
			}
			&_item {
				>p {
					margin-bottom: 15px;
					border-bottom: 2px solid var(--blue);
					font-weight: 500;
					letter-spacing: 0.05em;
					@include mq_not_maxW {
						font-size: fz_vw_clamp(24);
					}
					@include mq_maxW {
						font-size: fz(24);
					}
				}
			}
		}
		&_fund {
			a {
				position: relative;
				padding-bottom: 4px;
				letter-spacing: 0.05em;
				@include addLinedLink;
				@include mq_not_maxW {
					font-size: fz_vw_clamp(26);
				}
				@include mq_maxW {
					font-size: fz(26);
					line-height: 1.4;
				}
				&::before {
					background-color: var(--blue);
				}
			}
		}
		&_sns {
			@include mq_maxW {
				margin-top: 30px;
			}
			>ul {
				display: flex;
				flex-wrap: wrap;
				@include mq_not_maxW {
					gap: 20px min(40px, calc(40 / (var(--designW) + var(--scrollbarW)) * 100vw));
				}
				@include mq_maxW {
					gap: 20px 40px;
				}
			}
			a {
				display: grid;
				justify-content: center;
				@include addLinedLink('>.ly_header_body_sns_label');
			}
			&_img {
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				@include mq_not_maxW {
					height: calc(56 / 1200 * min(calc(1200 / var(--designW) * var(--htmlW)), 1200px));
				}
				@include mq_maxW {
					height: 33px;
				}
				img {
					max-height: 100%;
				}
			}
			&_label {
				position: relative;
				width: fit-content;
				margin: 0 auto;
				@include mq_not_maxW {
					font-size: fz_vw_clamp(18);
				}
				@include mq_maxW {
					font-size: fz(18);
				}
			}
		}
		&_footer {
			position: relative;
			z-index: 100;
			@include mq_not_maxW {
				display: grid;
				grid-template-columns: 1fr auto;
				align-items: center;
				height: 130px;
			}
			@include mq_maxW {
				padding-block: 46px 36px;
			}
			&_copy {
				font-weight: 800;
				line-height: 1.05;
				color: var(--blue);
				@include mq_not_maxW {
					padding-left: 24px;
					font-size: fz_vw_clamp(44);
				}
				@include mq_maxW() {
					padding-left: 10px;
					font-size: fz(34);
				}
			}
		}
	}
	&_closeBtn {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: var(--gray);
		@include mq_not_maxW {
			flex-direction: column;
			gap: 10px;
			width: 112px;
			height: 100%;
			padding-block: 30px 20px;
		}
		@include mq_maxW() {
			position: fixed;
			flex-direction: row-reverse;
			top: 0;
			right: 0;
			gap: 15px;
			width: 120px;
			height: 46px;
		}
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: var(--lightblue);
			// transform: scaleX(0);
			scale: 0 100%;
			transition: scale var(--transDur);
			transform-origin: center;
			z-index: 1;
		}
		&_icon {
			position: relative;
			display: inline-block;
			height: auto;
			aspect-ratio: 1;
			z-index: 2;
			@include mq_not_maxW {
				width: 40px;
			}
			@include mq_maxW {
				width: 20px;
			}
			&::before,
			&::after {
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				width: calc(100% * sqrt(2));
				height: 2px;
				border-radius: 10px;
				background-color: var(--blue);
			}
			&::before {
				transform: translate(-50%,-50%) rotate(-45deg);
			}
			&::after {
				transform: translate(-50%,-50%) rotate(45deg);
			}
		}
		&_txt {
			position: relative;
			font-weight: 500;
			color: var(--blue);
			z-index: 3;
			@include mq_not_maxW {
				font-size: fz(20);
			}
			@include mq_maxW {
				font-size: fz(16);
			}
		}
		@include action {
			&::before {
				// animation: scaleX 0.3s both ease-in-out;
				scale: 100% 100%;
			}
		}
	}
}
