// Sass変数（CSS変数が使えないメディアクエリ等のみで使う）
$breakPoint_sp: 767px;


// Function
@function max($values...) {
	@return unquote('max(#{$values})');
}
@function min($values...) {
	@return unquote('min(#{$values})');
}

@function removeUnit($number) {
	@if type-of($number) == 'number' and not unitless($number) {
		@return $number / ($number * 0 + 1);
	}
	@return $number;
}

// remベースのフォントサイズ算出
@function fz($fontSize) {
	@return calc($fontSize / 16 * 1rem);
}
// vwベースのフォントサイズ算出
@function fz_vw($fontSize) {
	@return calc($fontSize / (var(--designW) + var(--scrollbarW)) * 100vw);
}
// 最大・最小サイズも指定したい場合はこちら
@function fz_vw_clamp(
	$fontSize,       // デザインのフォントサイズ
	$maxRatio: 1, // $fontSizeに対してこの倍率まで大きくなることを許容
	$minRatio: 0.5  // $fontSizeに対してこの倍率まで小さくなることを許容
) {
	@return clamp(
		calc($fontSize * $minRatio * 1px),
		fz_vw($fontSize),
		calc($fontSize * $maxRatio * 1px)
	);
}


// Mixin
@mixin cf {
	&::after {
		content: "";
		display: block;
		clear: both;
	}
}

@mixin mq_maxW($breakPoint: $breakPoint_sp) {
	@media screen and (max-width: $breakPoint) {
		@content;
	}
}
@mixin mq_not_maxW($breakPoint: $breakPoint_sp) {
	@media not all and (max-width: $breakPoint), print {
		@content;
	}
}

@mixin hover {
	@media (any-hover: hover) {
		&:hover {
			@content;
		}
	}
}
@mixin action {
	&:focus-visible {
		@content;
	}
	@include hover {
		@content;
	}
}

@mixin addLinkIcon($target: null) {
	$target: if($target, $target, '&');

	#{$target} {
		&::after {
			display: inline-block;
			// background-color: currentColor;
			// mask-size: 100%;
			background-size: 100%;
			background-repeat: no-repeat;
		}
	}
	&[target="_blank"] {
		#{$target} {
			&::after {
				content: "";
				width: 20px;
				aspect-ratio: 4/3;
				background-image: url(../images/icon/icon_window.svg);
			}
		}
	}
	&[href$=".pdf"] {
		#{$target} {
			&::after {
				content: "";
				width: 15px;
				aspect-ratio: 3/4;
				background-image: url(../images/icon/icon_file.svg);
			}
		}
	}
	&[href*=".doc"],
	&[href$=".docx"],
	&[href$=".docm"],
	&[href$=".dotx"],
	&[href$=".dotm"] {
		#{$target} {
			&::after {
				content: "";
				width: 15px;
				aspect-ratio: 3/4;
				background-image: url(../images/icon/icon_file.svg);
			}
		}
	}
	&[href*=".xls"],
	&[href$=".xlsx"],
	&[href$=".xlt"],
	&[href$=".xml"],
	&[href$=".xlsm"],
	&[href$=".csv"] {
		#{$target} {
			&::after {
				content: "";
				width: 15px;
				aspect-ratio: 3/4;
				background-image: url(../images/icon/icon_file.svg);
			}
		}
	}
	&[href*=".ppt"],
	&[href$=".pptx"],
	&[href$=".pptm"] {
		#{$target} {
			&::after {
				content: "";
				width: 15px;
				aspect-ratio: 3/4;
				background-image: url(../images/icon/icon_file.svg);
			}
		}
	}
	&[href$=".zip"] {
		#{$target} {
			&::after {
				content: "";
				width: 15px;
				aspect-ratio: 3/4;
				background-image: url(../images/icon/icon_file.svg);
			}
		}
	}
}

// WYSIWYGでスタイルを踏襲することがあるため、各見出しのスタイルはmixinで定義
@mixin hdgLg {
	margin-bottom: 30px;
	font-size: fz(28);
	font-weight: bold;
	color: var(--blue);
}
@mixin hdgMd {
	margin-bottom: 20px;
	font-size: fz(22);
	font-weight: bold;
	color: var(--blue);
}
@mixin hdgSm {
	margin-bottom: 18px;
	font-size: fz(18);
	font-weight: bold;
	color: var(--blue);
}
@mixin hdgXs {
}


@mixin addLinedLink($target: null) {
	$target: if($target, $target, '&');
	#{$target} {
		// &::before {
		// 	content: "";
		// 	position: absolute;
		// 	bottom: 0;
		// 	left: 0;
		// 	width: 100%;
		// 	height: 2px;
		// 	background-color: currentColor;
		// 	transform: scaleX(0);
		// 	transform-origin: center;
		// }
		display: inline;
		box-decoration-break: clone;
		background-image: linear-gradient(currentColor 0%, currentColor 100%);
		background-repeat: no-repeat;
		background-size: 0 2px;
		background-position: 50% 100%;
		transition: background-size var(--transDur);
	}
	@include action() {
		#{$target} {
			// &::before {
			// 	animation: scaleX 0.3s both ease-in-out;
			// }
			background-size: 100% 2px;
		}
	}
}
