/**
 * Keyframes
 */

// @keyframes name {
// 	0% {
// 	}
// 	100% {
// 	}
// }

@keyframes rotateZ {
	0% {
		transform: rotate3d(0);
	}
	50% {
		transform: rotate3d(0.5, 0, 0, 90deg);
	}
	100% {
		transform: rotate3d(0.5, 0, 0, 180deg);
	}
}

@keyframes loopX {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

@keyframes scaleX {
    from {
        transform: scaleX(0);
    }
    to {
        transform: scaleX(1);
    }
}

@keyframes slideInL2R {
	0% {
		transform: translateX(-0.5em);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}
