// CSS変数
:root {
	--scrollbarW: 0;
	--htmlW: calc(100vw - var(--scrollbarW) * 1px);
	--negLeading: calc((1em - 1lh) / 2);
	--transEasing: ease-in-out;
	--transDur: 0.3s;
	// 以下はレイアウト系の変数
	// リキッドレイアウト、デザイン幅（＝ブラウザ幅）：1360px、その際のコンテンツ幅：1200pxで、その幅を最大とする場合
	// コンテンツ幅/デザイン幅でデザイン幅に対してコンテンツ幅を割合で指定しつつ、min()の第2引数に最大幅を指定することでそれ以上広がらないように
	--designW: 1360;
	@include mq_maxW() {
		--designW: 750;
	}
	--contW: min(1200 / var(--designW) * 100%, 1200px);
	@include mq_maxW() {
		--contW: calc(670 / var(--designW) * 100%);
	}
	--contMargin_hor: calc((100% - var(--contW)) / 2);
	// 以下コンテンツで使用
	--blue: #00205F;
	--lightblue: #a0d9f0;
	--skyblue: #23afe1;
	--hover: #0099CC;
	--gray: #EBEBEB;
}
:lang(en) {
	--negLeading: calc((1cap - 1lh) / 2);
}
